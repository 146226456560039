import React from "react";
import {
  Box,
  Divider,
  Flex,
  GridItem,
  Heading,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { GoCheck } from "react-icons/go";
import { useNavigate } from "react-router-dom";

export const EmphasisBook = (props) => {
  const { emphasisData } = props;
  const {
    destaquecollectionId,
    destaquebookTitle,
    destaquebookAuthor,
    destaquebookCover,
    // destaquebookJaEscolhido,
    destaqueMensagem,
  } = emphasisData;
  const navigate = useNavigate();

  return (
    <GridItem
      mb={6}
      h="min(40vw, 240px)"
      gridColumnStart={1}
      gridColumnEnd={{base: 4, md: 5}}
      boxShadow="0.5rem 0.5rem 1rem #00000033, inset 0 0 3rem #4DB2A833"
      borderRadius="2xl"
      p="3%"
      pos="relative"
      borderWidth="2px"
      borderColor="gblue"
    >
      <Flex
        clipPath="polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%)"
        top="-2px"
        right="3%"
        bgColor="gblue"
        w={{base: "14%", sm: "16%"}}
        minW={{base: "55px", sm: "70px"}}
        h={{base: "5rem", sm: "5.5rem", md: "6rem", lg: "7rem"}}
        flexDir="column"
        align="center"
        pos="absolute"
        fontSize={{base: "0.6rem", sm: "xs"}}
        color="#FFFFFFDD"
        fontWeight={700}
      >
        <Text h={{base: "12px", sm: "15px"}} marginBlockStart={0} marginBlockEnd={0} mt={{base: 1, md: 2}}>Vamolê</Text>
        <Text h={{base: "12px", sm: "15px"}} marginBlockStart={0} marginBlockEnd={0} mb={{base: 0, md: 1}}>Indica</Text>
        <Icon w={39} h={{base: 29, sm: 33, md: 33, lg: 39}} as={GoCheck} />
      </Flex>
      <Flex h="100%" gap="4%">
        <Box
          alignSelf="center"
          onClick={() => navigate(`/detalhes/${destaquecollectionId}`)}
          cursor="pointer"
          borderRadius="md"
          boxShadow="2px 2px 0.5rem #00000066"
          as={Image}
          src={destaquebookCover}
          objectFit="contain"
          objectPosition="center"
          h={{base: "75%", sm: "85%", md: "100%"}}
        />
        <Flex flexDir="column" flex={1}>
          <Heading
            fontWeight={900}
            color="gblue"
            fontSize={{base: "md", sm: "md", md: "xl", lg: "2xl"}}
            textTransform="uppercase"
          >
            Destaque
          </Heading>
          <Divider boxShadow="0 1px 0px #4DB2A8" mt={1.5} mb={1} w={{base: "60%", md: "64%"}} color="gblue" />
          <Text
            w={{base: "60%", md: "64%"}}
            fontWeight={700}
            fontSize={{base: "sm", sm: "md", lg: "lg"}}
            color="lightgray"
            noOfLines={2}
          >
            {destaquebookTitle}
          </Text>
          <Text
            w={{base: "60%", md: "64%"}}
            fontSize={{base: "xs", sm: "sm", lg: "md"}}
            color="gblue"
            mb={1.5}
            noOfLines={1}
          >
            {/* {destaquebookAuthor?.replace("De ", "")} */}
            {destaquebookAuthor}
          </Text>
          <Text
            fontSize={{base: "xs", sm: "sm", lg: "md"}}
            color="lightgray"
            noOfLines={{base: 1, sm: 2, lg: 3}}
          >
            {destaqueMensagem}
          </Text>
        </Flex>
      </Flex>
    </GridItem>
  );
};
