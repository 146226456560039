import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Main } from "./components/Main";
import { Home } from "./pages/home";
import { Help } from "./pages/ajuda";
import { TermsOfUse } from "./pages/termos";
import { TalkToUs } from "./pages/fale-conosco";
import { theme } from "./theme";
import { Login } from "./pages/login";
import { Catalog } from "./pages/catalogo";
import { getToken } from "./utils/helper";
import { useGetBooks } from "./hooks/useGetBooks";
import { BookDetails } from "./pages/detalhes";
import { Epub } from "./pages/epub";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const App = () => {
  const { getBooks } = useGetBooks();
  const TOKEN = getToken();

  useEffect(() => {
    if (!TOKEN) {
      if (window.location.pathname !== "/login")
        window.location.pathname = "/login";
      return;
    }

    if (window.location.pathname === "/login") window.location.pathname = "/";

    getBooks(TOKEN, true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TOKEN]);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Main hasLogo hasFooter showAlerts />}>
            <Route index element={<Home />} />
            <Route path="ajuda" element={<Help />} />
            <Route path="termos" element={<TermsOfUse />} />
            <Route path="fale-conosco" element={<TalkToUs />} />
          </Route>

          <Route path="/login" element={<Main bgColor="bgreen" />}>
            <Route index element={<Login />} />
          </Route>

          <Route
            path="/catalogo"
            element={<Main hasFooter />}
          >
            <Route index element={<Catalog pageName="Catálogo" />} />
          </Route>

          <Route path="/epub/:bookId" element={<Epub />} />

          <Route
            path="/detalhes"
            element={<Main hasFooter />}
          >
            <Route path=":bookId" element={<BookDetails pageName="Detalhes" />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
