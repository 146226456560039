import { Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useUserContext } from "../../context/userContext";
import { Book } from "./Book";
import { EmphasisBook } from "./EmphasisBook";
import { LibraryFilter } from "./LibraryFilter";

export const Library = (props) => {
  const { books, home, initialNumber, emphasisData, loadingCatalog } = props;
  const { getMoreCatalog, totalDeLivros, catSearch } = useUserContext();
  const [renderNumber, setRenderNumber] = useState(initialNumber);
  const [gridItems, setGridItems] = useState([]);

  const generateGridItems = () => {
    const items = [];

    if (!home && emphasisData?.destaquecollectionId) {
      items.push(<EmphasisBook key="first" emphasisData={emphasisData} />);
    }

    // if (loadingCatalog) {
    //   return items.push(
    //     <GridItem colStart={1} colEnd={4}>
    //       <TailSpin width={80} height={80} color="#4DB2A8" />
    //     </GridItem>
    //   );
    // }

    for (let i = 0; i < renderNumber; i++) {
      const item = books[i];
      const {
        bookId,
        collectionId,
        bookFavorito,
        bookCover,
        bookTitle,
        bookAuthor,
      } = item || {};
      const gridItem = item ? (
        <Book
          key={i}
          bookId={bookId || collectionId}
          favorited={bookFavorito}
          cover={bookCover}
          title={bookTitle}
          author={bookAuthor}
        />
      ) : (
        <Book key={i} empty />
      );

      items.push(gridItem);
    }

    setGridItems(items);
  };

  const handleSeeMore = () => {
    if (books?.length === undefined) return;

    const len = books.length;
    const diff = len - renderNumber;
    const diffFromTotal = totalDeLivros && totalDeLivros - len;

    if (renderNumber >= len) return;
    if (diff >= initialNumber) {
      if (diffFromTotal && diff <= 2 * initialNumber) {
        getMoreCatalog(len);
      }

      return setRenderNumber((n) => n + initialNumber);
    }

    setRenderNumber((n) => n + diff);
  };

  const contract = () => setRenderNumber(initialNumber);

  useEffect(() => {
    if (books) generateGridItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [books, renderNumber]);

  useEffect(() => {
    setRenderNumber(initialNumber);
  }, [initialNumber, catSearch]);

  return books ? (
    <Flex w="100%" flexDir="column" align="center">
      <LibraryFilter
        contract={contract}
        context={home ? "userLibrary" : "catalog"}
      />
      <Flex w="100%" pos="relative">
        {books.length === 0 && home && (
          <Flex pos="absolute" inset="0" align="center" justify="center">
            <Text
              color="orange"
              fontWeight={500}
              fontSize="2xl"
              maxW="14rem"
              textAlign="center"
            >
              Ainda não há livros na sua biblioteca
            </Text>
          </Flex>
        )}
        <Grid
          w="100%"
          templateColumns={{
            base: "repeat(3, calc((100% - 20px) / 3))",
            md: "repeat(4, calc((100% - 20px) / 4))",
          }}
          gap="10px"
        >
          {home || !loadingCatalog
            ? gridItems
            : [
                gridItems[0],
                <GridItem
                  as={Flex}
                  align="center"
                  justify="center"
                  colStart={1}
                  colEnd={{ base: 4, md: 5 }}
                >
                  <TailSpin width={80} height={80} color="#4DB2A8" />
                </GridItem>,
              ]}
        </Grid>
      </Flex>
      {books?.length > renderNumber && !loadingCatalog && (
        <Button
          _focus={{ boxShadow: "none" }}
          _active={{ boxShadow: "none" }}
          onClick={handleSeeMore}
          my={3}
          bgColor="gblue"
          color="white"
        >
          Ver Mais
        </Button>
      )}
    </Flex>
  ) : (
    <TailSpin width={80} height={80} color="#4DB2A8" />
  );
};
