import axios from "axios";
import { getToken } from "../utils/helper";

export const default_server = process.env.REACT_APP_DEFAULT_SERVER;

export const loginWithPhone = async (values) => {
  const phone = '55' + values.phone.replace(/[^a-zA-Z0-9]/g, '');
  const password = values.password;
  const payload = {
    msisdn: phone,
    cpfcnpj: password,
    produto: 'H',
    tipo: 1
  }

  try {
    const { data } = await axios.post(`${default_server}/getportfolio/`, payload);
    return data;
  }
  catch (error) {
    return false;
  }
};

export const loginWithCPF = async (values) => {
  const parsedcpfcnpj = values.cpf.replace(/[^0-9]+/g, '');
  const payload = {
    cpfcnpj: parsedcpfcnpj,
    produto: 'H',
    tipo: 2
  }

  try {
    const { data } = await axios.post(`${default_server}/getportfolio/`, payload);
    return data;
  }
  catch (error) {
    return false;
  }
};

export const sendMessage = async (values) => {
  const { name, email, phone, message } = values;

  const payload = {
    msisdn: `55${phone.replace(/[^a-zA-Z0-9]/g, "")}`,
    email: email,
    mensagem: message,
    nome: name,
    produto: "H"
  }

  try {
    const { data } = await axios.post(`${default_server}/email`, payload);
    return data;
  }
  catch (error) {
    return false;
  }
};

export const getPortfolio = async (token, linhainicial = 0, linhasadicionais = 20, tipoordena = 1) => {
  try {
    const { data } =
      await axios.get(
        `${default_server}/getportfolio/${token}`,
        {
          params: {
            produto: "H",
            linhainicial,
            linhasadicionais,
            tipoordena
          }
        });
    return data;
  }
  catch (error) {
    return {};
  }
};

export const getCatalog = async (token, linhainicial = 0, linhasadicionais = 20, tipoordena = 1, srch = "") => {
  try {
    const { data } =
      await axios.get(
        `${default_server}/catalogo/${token}`,
        {
          params: {
            produto: "H",
            linhainicial,
            linhasadicionais,
            tipoordena,
            srch
          }
        });
    return data;
  }
  catch (error) {
    return {};
  }
};

export const getLivro = async (values) => {
  try {
    const { data } = await axios.post(`${default_server}/getlivro/`, values);
    return data;
  }
  catch (error) {
    return {};
  }
};

export const chooseBook = async (colecao) => {
  const payload = {
    token: getToken(),
    produto: "H",
    colecao,
  };

  try {
    await axios.post(`${default_server}/livroescolhido/`, payload);
    return true;
  }
  catch (error) {
    return false;
  }
};

export const insertFavorite = async (colecao) => {
  const payload = {
    token: getToken(),
    acao: "INSERT",
    colecao,
  };

  try {
    await axios.post(`${default_server}/wishlist/`, payload);
    return true;
  }
  catch (error) {
    return false;
  }
};

export const deleteFavorite = async (colecao) => {
  const payload = {
    token: getToken(),
    acao: "DELETE",
    colecao,
  };

  try {
    await axios.post(`${default_server}/wishlist/`, payload);
    return true;
  }
  catch (error) {
    return false;
  }
};