import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const TitleWithSeparator = (props) => {
  const { title } = props;

  return (
    <Flex w="100%" align="center" my={3}>
      <Box flex={1} h="5px" borderY="1px solid orange" />
      <Text
        flex={0}
        whiteSpace="nowrap"
        color="orange"
        fontWeight={700}
        mx="10px"
      >
        {title}
      </Text>
      <Box flex={1} h="5px" borderY="1px solid orange" />
    </Flex>
  );
};

export { TitleWithSeparator };
