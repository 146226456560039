export const content = {
  how: [
    "O Vamolê é uma plataforma de livros em que você escolhe a cada mês um novo livro para sua biblioteca virtual. Os livros são seus, e você pode ler online e offline, baixando os livros escolhidos para seu celular, tablet ou computador.",
    "Você tem do primeiro ao último dia do mês para escolher um novo livro do nosso catálogo. Se não fizer a sua escolha, selecionaremos para você um livro pelo qual achamos que você pode se interessar. Os livros vão para sua biblioteca virtual, e você pode acessar quando quiser.",
    "Aproveite para conhecer o melhor da literatura e formar sua biblioteca virtual com títulos variados, para todas as idades.",
  ],
  faq: [
    {
      title: "Qual o prazo para escolher meu livro?",
      answer: "Você tem do primeiro ao último dia do mês para escolher um livro do nosso catálogo. ",
    },
    {
      title: "Quantos livros posso escolher?",
      answer: "Você escolhe um livro novo a cada mês para compor sua biblioteca. Aproveite para navegar pelo catálogo e favoritar outros títulos, que você pode escolher nos meses seguintes.",
    },
    {
      title: "Escolhi meu livro. Quando poderei escolher outro?",
      answer: "Como você, nós também somos leitores vorazes e estamos sempre ansiosos por novos títulos. Fique tranquilo que no primeiro dia do próximo mês você já poderá escolher seu novo livro.",
    },
    {
      title: "Se eu não escolher meu livro em determinado mês eu perco o livro?",
      answer: "Você pode até perder o prazo, mas não perde seu livro! Ainda que você esqueça ou não consiga fazer sua escolha, seu livro está garantido. Nos meses em que você não escolhe, nossa curadoria seleciona um livro para você com todo o cuidado.",
    },
    {
      title: " Posso trocar o livro escolhido? ", answer: "Depois que o livro já está na sua biblioteca, não é possível trocá-lo por outro. Mas calma que a gente ajuda a você a fazer as melhores escolhas. Antes de eleger um livro, clique em “espiar”. Assim você consegue ler as primeiras páginas, e se não for o que você esperava, pode optar por outro.",
    },
    {
      title: " Se eu não escolher determinado título num mês, vou ter outra chance de escolhê-lo? ", answer: "Ficou na dúvida? Não tem problema! Marque os livros que você mais gostar como favoritos, para localizá-los com mais facilidade e poder adicioná-los à sua biblioteca nos meses seguintes.",
    },
    {
      title: " Por que há livros na minha biblioteca que eu não escolhi? ", answer: "Se você não escolhe seu livro em determinado mês, nossa curadoria seleciona para você um título pelo qual achamos que você pode se interessar, e o colocamos em sua biblioteca. Pode ser que mesmo antes de você ter nos visitado pela primeira vez sua operadora já tivesse habilitado o seu acesso, então os primeiros livros de sua biblioteca foram escolhidos pela curadoria.",
    },
    {
      title: " Para que serve o download dos livros? ", answer: "Baixando os livros você poderá lê-los em qualquer e-reader que suporte o formato epub sem precisar estar conectado à internet e sem precisar acessar o Vamolê. Entre estes e-readers ou leitores digitais há excelentes opções gratuitas, como o Play Livros, para celulares Android, o Livros ou Apple Books para celulares iOS ou ainda o Adobe Digital Editions, para computador.",
    },
    {
      title: " Como faço para ler um livro já baixado? ", answer: "A primeira coisa a fazer é escolher e instalar um e-reader, ou leitor de e-books no seu aparelho, seja celular, tablet ou computador. Os livros baixados algumas vezes são carregados diretamente para seu e-reader, mas isso depende do seu dispositivo, do sistema operacional e do leitor que você escolheu. Então, se depois de baixado o livro não aparecer automaticamente no seu e-reader, procure pelo arquivo de extensão epub na área de downloads do seu aparelho. Ao tentar abri-lo o sistema operacional vai associá-lo ao e-reader e carregá-lo para lá. Para uma melhor experiência recomendamos o Play Livros, para dispositivos Android, o Livros (ou Apple Books) para dispositivos iOS e o Adobe Digital Edition para computador, todos gratuitos.",
    },
    {
      title: " Troquei de celular. Posso baixar os livros novamente? ", answer: "Pode! Você pode baixar novamente os livros da sua biblioteca para seu novo dispositivo. Observe que dependendo do e-reader que você usa e da forma como transferiu os dados do dispositivo antigo para o novo, seus livros já podem ter sido transferidos automaticamente para o novo smartphone.",
    },
    {
      title: " Posso compartilhar os livros baixados com outras pessoas? ", answer: "Odiamos dizer não pra você, mas neste caso é preciso! Os livros são protegidos por direitos autorais e só você, que tem uma conta Vamolê, pode acessá-los. Apesar de você ser o dono dos livros, enviá-los a outras pessoas é o mesmo que fazer fotocópias de livros físicos que você possua. No entanto, incentivamos a leitura compartilhada, especialmente dos livros infantis, na qual adultos e crianças leem juntos e partilham uma experiência incrível.",
    },
    {
      title: " Posso baixar meus livros para mais de um dispositivo? ", answer: "Se todos os dispositivos forem seus, e de seu uso pessoal, pode sim! Deste modo, você pode ler seus livros no celular, tablet e computador, por exemplo. Mas não vale baixar os livros para dispositivos de terceiros, pois você estaria desrespeitando os direitos autorias das obras. E se você gostou do livro e valoriza o autor, nunca faria isso, né?",
    },
    {
      title: " Troquei de operadora. Vou perder meus livros? ", answer: "Poxa...pena que você foi embora! Mas não se preocupe, os livros da sua biblioteca são seus, mesmo que você não seja mais cliente da nossa operadora parceira. Você continua com acesso ao Vamolê e à sua biblioteca virtual, apenas não escolherá um novo livro a cada mês.",
    },
  ]
};