import { Flex, Icon, Select, Text } from "@chakra-ui/react";
import { HiSortDescending } from "react-icons/hi";
import React from "react";
import { useUserContext } from "../../context/userContext";

export const LibraryFilter = (props) => {
  const { context, contract } = props;
  const data = useUserContext();
  const { libTypeSort, catTypeSort, setLibTypeSort, setCatTypeSort } = data;
  const { listOrdenacao } = data[context];
  const typeSort = {
    userLibrary: libTypeSort,
    catalog: catTypeSort,
  }[context];
  const handleChange = {
    userLibrary: (e) => {
      setLibTypeSort(e.target.value);
      contract();
    },
    catalog: (e) => {
      setCatTypeSort(e.target.value);
      contract();
    },
  }[context];

  return (
    <Flex align="center" alignSelf="flex-start" color="lightgray" mb={5} mt={2}>
      <Icon as={HiSortDescending} />
      <Text
        fontSize={{ base: "xs", sm: "md" }}
        fontWeight={700}
        mx="10px"
        whiteSpace="nowrap"
      >
        Ordenar por:
      </Text>
      <Select
        cursor="pointer"
        w="fit-content"
        h="fit-content"
        fontSize={{ base: "xs", sm: "md" }}
        bgColor="lightgray"
        color="white"
        borderRadius="full"
        value={typeSort}
        onChange={handleChange}
      >
        {listOrdenacao &&
          listOrdenacao.map(({ tipoordenacao, DescOrdenacao }) => (
            <option
              key={tipoordenacao}
              style={{ color: "#484848" }}
              value={tipoordenacao}
            >
              {DescOrdenacao}
            </option>
          ))}
      </Select>
    </Flex>
  );
};
