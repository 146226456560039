import React from "react";
import { Text, Flex, OrderedList, ListItem } from "@chakra-ui/react";

const Content = () => {
  return (
    <Flex flexDir="column">
      <Text p="10px" align="center" fontWeight={700} color="gray">
        TERMOS DE USO
      </Text>
      <Text p="10px" align="center" fontWeight={700} color="gray">
        REGULAMENTO DA PLATAFORMA VAMOLÊ
      </Text>

      <Text p="10px" color="gray" fontWeight={700}>
        1. O OBJETO
      </Text>

      <Text p="10px" color="gray">
        O Presente Termo regula as condições de uso do <strong>Vamolê</strong>,
        plataforma de distribuição de livros digitais que permite a seus
        usuários, doravante Participantes Usuários, receber mensalmente, um
        título em formato eletrônico, disponibilizados em catálogo de compras,
        que poderá ser escolhido pelos Participantes Usuários ou selecionado
        pela curadoria e baixado para leitura em seus dispositivos como
        smartphone, tablet ou computador.
      </Text>

      <Text p="10px" color="gray">
        O Regulamento ora apresentado, segue, dentre outros, os princípios da
        informação, transparência e boa-fé, observando as leis nº 8.078/1990 e
        nº 10.406/2002.
      </Text>

      <Text p="10px" color="gray">
        Os Participantes Usuários declaram que ao acessarem e utilizarem a
        plataforma <strong>Vamolê</strong>, leram integralmente e aceitaram os
        termos deste regulamento na forma como aqui expressos, tendo acesso aos
        títulos oferecidos.
      </Text>

      <Text p="10px" color="gray" fontWeight={700}>
        2. O ACESSO AO VAMOLÊ
      </Text>

      <Text p="10px" color="gray">
        2.1. Poderão ser Participantes Usuários somente as pessoas jurídicas e
        as pessoas físicas capazes maiores de 18 (dezoito) anos.
      </Text>

      <Text p="10px" color="gray">
        2.2. O Participante Usuário, nos termos da legislação aplicável à
        espécie, declara estar ciente, desde já, que a partir do seu cadastro
        para utilização do Programa, e consequente aceitação deste Regulamento,
        é responsável pelos seus atos na utilização do <strong>Vamolê</strong>,
        no caso de qualquer conduta ilícita, na forma das legislações vigentes,
        bem como pela eventual utilização indevida do terminal de serviço móvel
        pessoal utilizado e por propagação do material disponibilizado sem
        prévia e expressa autorização.
      </Text>

      <Text p="10px" color="gray">
        2.3. Após ciência de todos os termos do presente regulamento, o
        Participante Usuário, ao seu exclusivo critério, terá acesso,
        mensalmente, às edições digitais disponíveis para aquisição, sendo de
        sua inteira faculdade a escolha do título adquirido, observando os
        prazos previstos no item 3.5.
      </Text>

      <Text p="10px" color="gray">
        2.3.1 Todos os títulos oferecidos e adquiridos, estarão disponíveis para
        leitura on-line dentro da plataforma. Observando o item 2.4, a
        disponibilização de recursos adicionias, como narração, apresentação em
        forma de audiolivro e possibilidade de download do livro fica a critério
        exclusivo e intrasnferível da Plataforma Vamolê.2.4. Os títulos cujo
        download será permitido serão disponibilizados em arquivo formato ePub,
        e poderão ser acessados de qualquer e-reader (leitor de livro digital),
        inclusive os gratuitos, com suporte a este formato.
      </Text>

      <Text p="10px" color="gray">
        2.4. Os títulos cujo download será permitido serão disponibilizados em
        arquivo formato ePUB, e poderão ser acessados de qualquer e-reader
        (leitor de livro digital), inclusive os gratuitos, com suporte a este
        formato.
      </Text>

      <Text p="10px" color="gray">
        2.5. Antes da leitura on-line e de fazer download o título oferecido e
        adquirido, o Participante Usuário deverá reler integralmente este
        Regulamento. Caso opte pelo download do livro (item 2.3.1), o usuário
        concordará automaticamente que houve expressa aceitação e concordância
        de todas as regras, termos e condições previstos neste Regulamento. Não
        será possível a utilização do <strong>Vamolê</strong> caso haja qualquer
        discordância aos presentes termos.
      </Text>

      <Text p="10px" color="gray">
        2.6. O Participante Usuário se declara proprietário dos exemplares
        adquiridos e baixados que lhe foram disponibilizados na plataforma{" "}
        <strong>Vamolê</strong>.
      </Text>

      <Text p="10px" color="gray">
        2.7. Além do download do livro disponibilizado e adquirido, é conferida
        ao Participante Usuário a possibilidade da leitura online, destacando
        que algumas obras contarão com o recurso de acessibilidade de narração.
      </Text>

      <Text p="10px" color="gray">
        2.7.1. O critério de escolha das obras que contarão com o recurso de
        acessibilidade de narração, é de exclusividade do Vamolê e
        intransferível.
      </Text>

      <Text p="10px" color="gray">
        2.7.2. O recurso de acessibilidade de narração ficará disponível somente
        para leitura on-line dentro da plataforma Vamolê, não acompanha o
        arquivo ePub e dentro do prazo previso no item 3.11.
      </Text>

      <Text p="10px" color="gray">
        2.8. A elegibilidade ao <strong>Vamolê</strong> é definida a critério
        exclusivo da empresa parceira a que o Participante Usuário está
        vinculado, desde que essa mantenha acordo comercial com a{" "}
        <strong>Vamolê</strong>.
      </Text>

      <Text p="10px" color="gray">
        2.9. A plataforma <strong>Vamolê</strong> é destinada ao uso pelo
        público adulto, este entendido como o maior capaz previsto no item 2.1 e
        definido nos arts. 3º, 4º e 5º da Lei nº 10.406/2002, sendo certo que
        caberá o adulto Usuário Participante a seleção dos conteúdos a terem o
        aceso franqueado aos menores sob sua supervisão.
      </Text>

      <Text p="10px" color="gray">
        2.10. A despeito da criteriosa seleção de títulos e curadoria do
        conteúdo oferecido, o <strong>Vamolê</strong> não se responsabiliza, em
        hipótese alguma, por quaisquer danos que a exposição do conteúdo possa
        causar, particularmente sobre público inadequado e terceiros, bem como o
        acesso a plataforma por menores de 18 (dezoito) anos, cabendo ao
        Participante Usuário, não permitir o acesso e a exposição do conteúdo
        adulto, sobretudo ao público infantil.
      </Text>

      <Text p="10px" color="gray">
        2.11. É de responsabilidade exclusiva dos Participantes Usuários a opção
        pelo acesso e download dos títulos oferecidos e adquiridos, inclusive na
        hipótese de negligência, assim como fornecer acesso ao conteúdo a
        terceiros não usuários da plataforma Vamolê e a menores de 18 anos de
        idade, independente de assistência ou representação.
      </Text>

      <Text p="10px" color="gray" fontWeight={700}>
        3. A PARTICIPAÇÃO E A UTILIZAÇÃO DE CONTEÚDO
      </Text>

      <Text p="10px" color="gray">
        3.1. O <strong>Vamolê</strong> é uma plataforma exclusiva, de adesão
        voluntária pelos Participantes Usuários vinculados às empresas parceiras
        do <strong>Vamolê</strong>, sendo a participação dos Participantes
        Usuários condicionada ao vínculo com uma ou mais empresa parceira,
        ficando a cargo das empresas parceiras a forma de remuneração para
        aquisição dos livros disponibilizados na plataforma. Assim, a
        elegibilidade à plataforma é definida pelas empresas parceiras a seu
        exclusivo critério.
      </Text>

      <Text p="10px" color="gray">
        3.2. É direito dos Participantes Usuários, a qualquer tempo e sem
        necessidade de justificativa prévia, solicitar a suspensão do envio de
        comunicações pelo <strong>Vamolê</strong> mediante comunicação expressa,
        diretamente à <strong>Vamolê</strong> por meio da seção Fale Conosco ou
        aos canais de relacionamento da operadora parceira a qual esteja
        vinculado. A suspensão de comunicados não cancela a elegibilidade dos
        Participantes Usuários, que poderão, a qualquer momento, enquanto
        elegível, solicitar restabelecimento do envio de comunicações do
        <strong>Vamolê</strong>.
      </Text>

      <Text p="10px" color="gray">
        3.3. Poderá, a qualquer momento e a critério exclusivo e iniciativa da
        operadora parceira da <strong>Vamolê</strong>, a qual o Participante
        Usuário está vinculado, haver o cancelamento da participação de usuários
        na medida que estas apontem ao <strong>Vamolê</strong> o fim da
        elegibilidade do Participante Usuário. O cancelamento acarretará o não
        recebimento de novos títulos pelo Participante Usuário.
      </Text>

      <Text p="10px" color="gray">
        3.4. O cancelamento da elegibilidade do Participante Usuário não afeta o
        acesso e a leitura dos conteúdos a este já oferecidos e adquiridos,
        observado o prazo do item 3.5, sendo de propriedade do Participante
        Usuário os títulos por ele baixados.
      </Text>

      <Text p="10px" color="gray">
        3.5. Todo o novo conteúdo oferecido mensalmente ao Usuário Participante
        estará disponível dentro do período de escolha, iniciando este no 1º
        (primeiro) dia do mês e terminando no último dia do mesmo mês,
        obedecendo o que dispõe o art. 3º, inciso II do Decreto nº 7.962 de 15
        de março de 2013. Após este período, não será possível para o Usuário
        participante adquirir os títulos oferecidos em datas anteriores,
        observando ainda as regras de acesso do participante usuário previstas
        no item 3.11.
      </Text>
      <Text p="10px" color="gray">
        3.6. Caso o Participante Usuário se mantenha inerte dentro do período de
        escolha mencionado na cláusula 3.5 e não faça a escolha do livro
        disponibilizado no catálogo de compra, autoriza este, desde já, que seja
        acrescentado um livro à sua biblioteca virtual, sem que tal conduta
        importe em prática abusiva ou violação de direito.
      </Text>
      <Text p="10px" color="gray">
        3.7. Considerando os princípios da informação e transparência, a escolha
        mensal do livro nas hipóteses de inércia do participante usuário, será
        efetuada pela curadoria do <strong>Vamolê</strong> de acordo com seus
        próprios critérios.
      </Text>
      <Text p="10px" color="gray">
        3.8. Nas hipóteses dos itens 3.6. e 3.7, o Participante Usuário terá
        posse definitiva do livro escolhido, não havendo possibilidade de troca,
        substituição compensação, indenização ou reparação, devendo o
        Participante Usuário promover o download das obras que integram sua
        biblioteca virtual em dispositivo eletrônica de sua propriedade.
      </Text>
      <Text p="10px" color="gray">
        3.9. Os Participantes Usuários deverão estar cientes de que o acesso ao{" "}
        <strong>Vamolê</strong> e o download de títulos depende de tráfego de
        dados móveis, estando, pois, sujeito à consumo de seu pacote de dados,
        tarifação e encargos pela operadora de banda larga ou telefonia móvel.
      </Text>
      <Text p="10px" color="gray">
        3.10. O <strong>Vamolê</strong> reserva-se o direito de, a qualquer
        momento e a seu exclusivo critério, descontinuar, suspender, terminar a
        oferta de produtos, ou ainda alterar a quantidade, o formato e a
        periodicidade dos títulos oferecidos, mantendo a disponibilidade
        prevista no item 3.5.
      </Text>
      <Text p="10px" color="gray">
        3.11. Eventual encerramento/descontinuidade do <strong>Vamolê</strong>{" "}
        será comunicado na própria plataforma, tendo os Usuários Participantes a
        possibilidade de fazer download de todos os livros escolhidos/adquiridos
        que integram sua biblioteca virtual, dentro do período de 12 (doze)
        meses, a contar da data de encerramento da plataforma para assegurar a
        posse definitiva dos livros adquiridos. No caso de encerramento, os
        recursos próprios da plataforma, como leitura online e acessibilidade,
        serão descontinuados.
      </Text>
      <Text p="10px" color="gray">
        3.12. Ultrapassado o prazo indicado no item 3.11, os livros deixarão de
        estar disponíveis na plataforma <strong>Vamolê</strong>, sem que seja
        afetada a posse definitiva dos livros baixados.
      </Text>
      <Text p="10px" color="gray">
        3.13. Caso o Participante Usuário não faça o download do livro dentro do
        prazo indicado no item 3.11., não confere a este a possibilidade de
        substituição compensação, indenização ou reparação, independente de
        natureza e/ou extensão.
      </Text>
      <Text p="10px" color="gray">
        3.14. A plataforma <strong>Vamolê</strong> é de uso pessoal e não
        comercial, onde os Participantes Usuários poderão ser excluídos e/ou ter
        a plataforma suspensa, definitiva ou temporariamente, pelos seguintes
        motivos, dentre outros:
      </Text>

      <OrderedList spacing="10px">
        <ListItem>
          Compartilhamento, cessão, comercialização, divulgação ou impressão dos
          arquivos baixados;
        </ListItem>
        <ListItem>
          Modificar, adaptar, licenciar, transferir, editar, publicar, traduzir,
          promover publicação em qualquer outro meio de comunicação, criar
          outras obras derivadas das disponibilizadas, ou de qualquer forma
          explorar ou tirar proveito do conteúdo digital da plataforma{" "}
          <strong>Vamolê</strong>;
        </ListItem>
        <ListItem>
          Comercializar, locar ou transferir o acesso ao conteúdo digital da
          plataforma <strong>Vamolê</strong>;
        </ListItem>
        <ListItem>
          Adaptar, modificar, comercializar, distribuir, realizar engenharia no
          código fonte da plataforma ou praticar qualquer ato que comprometa,
          total o parcialmente, seu funcionamento, disponibilidade e/ou
          desempenho;
        </ListItem>
        <ListItem>
          Identificação de qualquer indício ou prova de vício, fraude ou
          irregularidade na participação Participante Usuário;
        </ListItem>
        <ListItem>
          Por solicitação dos próprios Participantes Usuários ou de responsável
          devidamente habilitado;
        </ListItem>
        <ListItem>
          Por solicitação judicial ou de autoridade competente;
        </ListItem>
        <ListItem>
          Por determinação e/ou a critério exclusivo da operadora associada;
        </ListItem>
        <ListItem>
          Se apurado o mau uso do <strong>Vamolê</strong>, além de infringir a
          legislação aplicável, especialmente de natureza cível e criminal, bem
          como afronta aos princípios éticos, morais e de boa-fé, ou caso haja
          violação de alguma das disposições contidas neste Termo.
        </ListItem>
      </OrderedList>

      <Text p="10px" color="gray" fontWeight={700}>
        4. PROPRIEDADE INTELECTUAL
      </Text>
      <Text p="10px" color="gray">
        4.1. Todos os conteúdos disponibilizados pela <strong>Vamolê</strong>,
        incluindo a própria plataforma, estão protegidos pelas Leis de Direitos
        Autorais e Propriedade Intelectual.
      </Text>
      <Text p="10px" color="gray">
        4.2. O Participante Usuário é o proprietário dos conteúdos
        disponibilizados e por ele baixados, sendo que, por estarem estes
        protegidos pelas leis de direitos autorias, é expressamente proibido ao
        Participante Usuário reproduzir, ainda que parcialmente, transmitir,
        veicular, ceder, negociar ou distribuir qualquer título.
      </Text>
      <Text p="10px" color="gray">
        4.3. Ao optar por fazer o download de qualquer conteúdo, o Participante
        Usuário concorda em zelar pelos conteúdos baixados e respeitar os
        direitos autorais e a propriedade intelectual de autores e da
        plataforma, assumindo civil e criminalmente a responsabilidade por
        eventuais violações e danos de natureza moral e material causados a
        outrem, independente de natureza e extensão, inclusive nas hipóteses de
        regresso.
      </Text>
      <Text p="10px" color="gray" fontWeight={700}>
        5. DAS DISPOSIÇÕES GERAIS
      </Text>
      <Text p="10px" color="gray">
        5.1. Os Participantes Usuários declaram que tem conhecimento de que ao{" "}
        <strong>Vamolê</strong> não é responsável pelos conteúdos distribuídos,
        que são de responsabilidade única dos autores dos títulos e editoras.
      </Text>
      <Text p="10px" color="gray">
        5.2. O <strong>Vamolê</strong> declara que não armazena os dados
        pessoais dos Participantes Usuários tampouco compartilha com terceiros
        sua atividade relacionada a visitas e downloads.
      </Text>
      <Text p="10px" color="gray">
        5.3. Os Participantes Usuários reconhecem o direito do{" "}
        <strong>Vamolê</strong> a qualquer tempo e por ato unilateral, de
        aditar, modificar ou atualizar as regras, termos e condições deste
        Regulamento. Qualquer aditamento, modificação ou atualização serão
        previamente comunicados aos Participantes Usuários, por meio do site ou
        por correio eletrônico. Caso os Participantes Usuários continuem a usar
        a <strong>Vamolê</strong>
        depois do aviso de aditamento, modificação ou atualização deste
        Regulamento, isso caracterizará a aceitação das alterações
        implementadas. Excluem-se as modificações que gerem ônus ou novas
        cobranças ao usuário.
      </Text>
      <Text p="10px" color="gray">
        5.4. O <strong>Vamolê</strong> poderá, a qualquer tempo e por ato
        unilateral, ser suspenso, extinto ou modificado, mediante prévia
        comunicação aos Participantes Usuários na plataforma. Em qualquer
        hipótese, será resguardada eventual operação comercial já concluída
        pelos Participantes Usuários até a data da suspensão, extinção ou
        modificação, observado o disposto no item 3.11.
      </Text>
      <Text p="10px" color="gray">
        5.5. Os Participantes reconhecem a obrigatoriedade de observar as leis
        de proteção ao direito autoral e propriedade intelectual e concordam em
        fazê-lo, estando sujeitos, em contrário, às penalidades cíveis e
        criminais aplicáveis.
      </Text>
      <Text p="10px" color="gray">
        5.6. Os casos omissos neste Regulamento serão tratados diretamente entre
        os Participantes Usuários e o <strong>Vamolê</strong>, observando a
        legislação pertinente.
      </Text>
      <Text p="10px" color="gray">
        5.7. Qualquer tolerância ao descumprimento ou omissão na execução de
        seus direitos por parte do <strong>Vamolê</strong>, não constituirá
        renúncia, ineficácia ou novação dos direitos e obrigações ora pactuados,
        nem impedirá que o <strong>Vamolê</strong> ou qualquer dos autores e/ou
        editores veiculados na plataforma, ainda que extemporaneamente,
        requeira o cumprimento de tais direitos e obrigações, sendo certo que
        qualquer exceção só se tornará valida desde que expressamente concedida
        pelo <strong>Vamolê</strong> ou qualquer dos parceiros disponibilizados
        por meio da plataforma.
      </Text>
      <Text p="10px" color="gray">
        5.8. O <strong>Vamolê</strong> não será considerado em mora ou
        inadimplente em relação a qualquer direito ou obrigação previstos neste
        Regulamento se o motivo do descumprimento decorrer de caso fortuito ou
        força maior, na forma estabelecida pelo Código Civil Brasileiro, Lei. nº
        10.406, de 10 de janeiro de 2002 e pelo Código de Proteção e Defesa do
        Consumidor, Lei nº 8.078 de 11 de setembro de 1990.
      </Text>
      <Text p="10px" color="gray">
        5.9. Na hipótese de qualquer das regras, termos ou condições aqui
        dispostos ser considerado, por qualquer motivo, por juízo ou árbitro
        competente, inválido, inexequível ou ilegal, no todo ou em parte, a
        validade e exequibilidade das demais regras, termos e condições, ou de
        partes deles, não serão afetadas, pois, tratam-se de regras
        independentes entre si, nos termos do que dispõe o art. 51, § 2º da lei
        8.078/90.
      </Text>
      <Text p="10px" color="gray">
        5.10. nos termos do que dispõe a Lei Geral de Proteção de Dados (lei
        13.709/18) e demais normas que versem sobre privacidade e proteção de
        dados pessoais, o Vamolê assegura que os Dados Pessoais não serão
        acessados, compartilhados ou transferidos para terceiros, incluindo
        eventuais subcontratados, agentes autorizados e afiliados.
      </Text>
      <Text p="10px" color="gray">
        5.11. Independentemente do local no território, nacional ou
        internacional, que os Participantes Usuários tenham acesso ao ambiente
        de internet do <strong>Vamolê</strong>, este Regulamento será regido,
        interpretado e executado de acordo com as leis da República Federativa
        do Brasil, independentemente dos conflitos dessas leis com leis de
        outros estados ou países, sendo competente a Justiça Brasileira para
        dirimir qualquer dúvida decorrente deste instrumento. O aceite ao
        presente Regulamento estabelece a renúncia dos Participantes Usuários à
        competência de qualquer outra autoridade judiciária, FICANDO DESDE JÁ
        ELEITO O FORO DO DOMICÍLIO DO PARTICIPANTE USUÁRIO como competente para
        dirimir qualquer questão oriunda do presente Regulamento, por mais
        privilegiado que seja ou venha a ser.
      </Text>
    </Flex>
  );
};

const TermsOfUse = () => {
  return <Content />;
};

export { TermsOfUse };
