import React, { useState, createContext, useContext } from "react";

export const getCurrentIndex = (path) => {
  const currentPath = path || window.location.pathname;
  const paths = {
    "/ajuda": 0,
    "/fale-conosco": 1,
    "/": 2,
    "/termos": 3,
  };

  return paths[currentPath];
};

export const PathContext = createContext();

export const PathProvider = ({children}) => {
  const [activeIndex, setActiveIndex] = useState(getCurrentIndex());

  return (
    <PathContext.Provider value={{ activeIndex, setActiveIndex }}>
      {children}
    </PathContext.Provider>
  );
};

export const usePathContext = () => {
  const context = useContext(PathContext);

  return context;
};
