import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { TitleWithSeparator } from "../../components/TitleWithSeparator";
import { ChooseBookIndicator } from "./components/ChooseBookIndicator";
import { useUserContext } from "../../context/userContext";
import { Library } from "../../components/Library";

export const Home = () => {
  const [initialNumber, setInitialNumber] = useState(
    window.innerWidth >= 440 ? 12 : 9
  );
  const { userLibrary, loadingHome } = useUserContext();
  const { currentMonthName, livroDoMes, listData } = userLibrary;

  window.addEventListener("resize", (ev) => {
    const width = ev?.target?.innerWidth;
    const newNumber = width >= 440 ? 12 : 9;

    newNumber !== initialNumber && setInitialNumber(newNumber);
  });

  return (
    <Flex w="100%" flexDir="column" alignItems="center">
      <ChooseBookIndicator
        currentMonthName={currentMonthName}
        livroDoMes={livroDoMes}
      />
      <TitleWithSeparator title="Minha biblioteca" />
      <Library initialNumber={initialNumber} home books={loadingHome ? undefined : listData} />
    </Flex>
  );
};
