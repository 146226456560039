import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { useGetBooks } from "../../hooks/useGetBooks";
import { getToken, requestFS } from "../../utils/helper";
import {
  chooseBook,
  deleteFavorite,
  getCatalog,
  insertFavorite,
} from "../../services/api";
import AudioPlayerModal from "../../components/AudioPlayerModal";
import { InternalHeader } from "../../components/InternalHeader";

export const BookDetails = (props) => {
  const { pageName } = props;
  const { bookId } = useParams();
  const { getBooks, getBookDetails } = useGetBooks();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    bookDetails,
    userLibrary,
    setLibTypeSort,
    catalog,
    setCatalog,
    setCatLinhaInicial,
    setCatLinhaAdicional,
    setCatTypeSort,
  } = useUserContext();

  const { livroDoMes } = userLibrary || {};
  const [audioOpen, setAudioOpen] = useState(false);
  const {
    bookTitle,
    bookAuthor,
    bookIllustrator,
    bookCover,
    bookReviewText,
    bookDownloadFile,
    bookAudioFull,
    bookAudioFullSpeaker,
  } = bookDetails || {};
  const findInCatalog = (catalog?.listData || []).find(
    (b) => b.collectionId === bookId
  );
  const isCatalogBook = !!findInCatalog;
  const isEmphasis = bookId === catalog?.destaquecollectionId;
  const chosen = isCatalogBook && findInCatalog?.bookFavorito;
  const findInLibrary = (userLibrary?.listData || []).find(
    (b) => b.bookId === bookId
  );
  const isLibraryBook = !!findInLibrary;

  const deleteFavBook = async () => {
    const response = await deleteFavorite(bookId);

    if (response === false) {
      return toast({
        title: "Algo deu errado",
        description: "Erro ao desfavoritar o livro. Tente novamente.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }

    const newCatalog = await getCatalog(getToken(), 0, 20, 1, "");
    setCatalog(newCatalog);
    setCatLinhaInicial(0);
    setCatLinhaAdicional(20);
    setCatTypeSort(1);

    toast({
      title: "Sucesso!",
      description: "O livro foi retirado da sua lista de desejos!",
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  const insertFavBook = async () => {
    const response = await insertFavorite(bookId);

    if (response === false) {
      return toast({
        title: "Algo deu errado",
        description: "Erro ao favoritar o livro. Tente novamente.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }

    const newCatalog = await getCatalog(getToken(), 0, 20, 1, "");
    setCatalog(newCatalog);
    setCatLinhaInicial(0);
    setCatLinhaAdicional(20);
    setCatTypeSort(1);

    toast({
      title: "Sucesso!",
      description: "O livro foi incluído na sua lista de desejos!",
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  const buttons = {
    true: [
      {
        text: "Ler",
        color: "#F7972E",
        onClick: () => {
          requestFS();
          setTimeout(() => navigate(`/epub/${bookId}`), 200);
        },
      },
      {
        text: "Ouvir",
        color: "#ED1774",
        disabled: !bookAudioFull,
        onClick: () => setAudioOpen(true),
      },
      {
        text: "Baixar",
        color: "#1E67B1",
        disabled: !bookDownloadFile,
        onClick: () =>
          bookDownloadFile && window.open(bookDownloadFile, "_self"),
      },
    ],
    false: [
      {
        text: "Espiar",
        color: "#ED1774",
        onClick: () => {
          requestFS();
          setTimeout(() => navigate(`/epub/${bookId}`, { state: { spy: true } }), 200);
        },
      },
      {
        text: chosen ? "Desfavoritar" : "Favoritar",
        color: "#FF8A00",
        onClick: chosen ? deleteFavBook : insertFavBook,
      },
      {
        text: bookId === livroDoMes ? "Escolhido" : "Escolher este",
        color: "#78BF37",
        disabled: !!livroDoMes,
        onClick: async () => {
          const response = await chooseBook(bookId);

          if (!response)
            return toast({
              title: "Algo deu errado",
              description: "Erro ao escolher o livro. Tente novamente.",
              status: "error",
              duration: 6000,
              isClosable: true,
            });

          toast({
            title: "Livro escolhido com sucesso!",
            description: "Seu novo livro já está na biblioteca!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });

          await setLibTypeSort(1);
          await getBooks(getToken(), true, true);
          setTimeout(() => navigate("/"), 1000);
        },
      },
    ],
  };

  const renderButtons = buttons[isLibraryBook];
  const showContent = bookDetails && bookDetails.bookId === bookId;

  useEffect(() => {
    const TOKEN = getToken();
    if (!TOKEN) return;

    getBookDetails(TOKEN, bookId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId]);

  return showContent ? (
    <Flex flexDir="column">
      {pageName && (
        <InternalHeader
          isCatalogBook={isCatalogBook}
          isEmphasis={isEmphasis}
          pageName={pageName}
        />
      )}
      {audioOpen && (
        <AudioPlayerModal
          isOpen={audioOpen}
          audioUrl={bookAudioFull}
          bookTitle={bookTitle}
          authorName={bookAuthor}
          narratorName={bookAudioFullSpeaker}
          onHideModal={() => setAudioOpen(false)}
        />
      )}
      <Flex w="100%" justify="space-between">
        <Box w="40%">
          <Box my={3}>
            <Text
              color="lightgray"
              fontWeight={700}
              fontSize={{ base: "sm", sm: "md", md: "lg" }}
              noOfLines={3}
            >
              {bookTitle}
            </Text>
            <Text
              color="lightgray"
              fontSize={{ base: "xs", sm: "sm" }}
              noOfLines={2}
            >
              {/* Autor: {bookAuthor.replace("De ", "")} */}
              {bookAuthor}
            </Text>
            {bookIllustrator && (
              <Text
                color="lightgray"
                fontSize={{ base: "xs", sm: "sm" }}
                noOfLines={2}
              >
                {/* Ilustrações: {bookIllustrator.replace("Ilustrações de ", "")} */}
                {bookIllustrator}
              </Text>
            )}
          </Box>
          <Flex flexDir="column" gap="5px">
            {renderButtons.map(({ color, text, disabled, onClick }) => (
              <Button
                _focus={{ boxShadow: "none" }}
                _active={{ boxShadow: "none" }}
                disabled={disabled}
                _disabled={{
                  filter: "opacity(20%) grayscale(100%)",
                  cursor: "not-allowed",
                  display: "none",
                }}
                key={text}
                onClick={onClick}
                color="white"
                bgColor={color}
                fontSize="sm"
                h="1.8rem"
              >
                {text}
              </Button>
            ))}
          </Flex>
        </Box>
        <Flex
          h="fit-content"
          justify="flex-end"
          w="40%"
          maxW="180px"
          pos="relative"
          top="7%"
          right="7%"
        >
          <Image
            maxH="min(50vw, 250px)"
            src={bookCover}
            fit="contain"
            borderRadius="md"
            transform="rotate(7deg)"
            boxShadow="4px 4px 7px #00000055"
          />
        </Flex>
      </Flex>
      <Flex flexDir="column" my={10}>
        <Text color="#1FAA9C" fontWeight={500} mb={3}>
          Sobre o livro
        </Text>
        <Text
          color="lightgray"
          lineHeight={{ base: "1.2rem", sm: "1.4rem", md: "1.6rem" }}
          fontSize={{ base: "sm", sm: "md", md: "lg" }}
        >
          {bookReviewText}
        </Text>
      </Flex>
    </Flex>
  ) : (
    <>
      <TailSpin color="#4DB2A8" height={80} width={80} />
    </>
  );
};
