import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    primary: "#A7CA5C",
    secondary: "#8BC26E",
    gblue: "#4DB2A8",
    ligreen: "#BBE247",
    bgreen: "#87BD94",
    gray: "#333333",
    othergray: "#484848",
    lightgray: "#7d7d7d",
    buttongreen: "#B4E22A",
  },
  fonts: {
    primary: "Open Sans, sans-serif",
    secondary: "Roboto, sans-serif",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  breakpoints: {
    xs: '320px',
    sm: '360px',
    md: '440px',
    lg: '520px',
  }
});
