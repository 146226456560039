import { isIOSDevice } from './device'
import { lockOrientation } from './lockOrientation'

export const sleep = (ms) => {
  return new Promise((resolve, _) => {
    setTimeout(resolve, ms)
  })
}

export const requestFS = () => {
  if (isIOSDevice) {
    // ADDING BODY CSS RULE TO DISABLE IOS PULL-TO-REFRESH
    document.body.style.overflow = "hidden";
    return;
  }
  var doc = window.document
  var docEl = doc.documentElement
  const fsel = doc.fullscreenElement

  var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;

  !fsel && requestFullScreen.call(docEl)
  setTimeout(lockOrientation, 500)
}

export const cancelFS = () => {
  if (isIOSDevice) {
    // REMOVING BODY CSS RULE TO DISABLE IOS PULL-TO-REFRESH
    document.body.style.overflow = "auto";
    return;
  }
  var doc = window.document
  const fsel = doc.fullscreenElement

  var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  fsel && cancelFullScreen.call(doc)
}

export const getToken = () => {
  return localStorage.getItem("@vml/hash")
}