import { Flex, IconButton, Text } from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import React from "react";
import { useNavigate } from "react-router-dom";
import { usePathContext } from "../../context/pathContext";

export const InternalHeader = (props) => {
  const { pageName, isCatalogBook, isEmphasis } = props;
  const navigate = useNavigate();
  const { setActiveIndex } = usePathContext();
  setActiveIndex(2);

  const handleGoBack = () => {
    navigate((isCatalogBook || isEmphasis) ? "/catalogo" : "/");
  };

  return (
    <Flex
      pos="fixed"
      top={0}
      zIndex={1}
      w="100%"
      maxW="600px"
      left="50%"
      transform="translateX(-50%)"
      h="50px"
      py="10px"
      pl="10px"
      pr="20px"
      fontWeight={700}
      bgColor="secondary"
      color="othergray"
      boxShadow="md"
      align="center"
    >
      <IconButton
        onClick={handleGoBack}
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
        pos="absolute"
        borderRadius="full"
        icon={<BiArrowBack />}
      />
      <Text mx="auto">{pageName}</Text>
    </Flex>
  );
};