import { useUserContext } from "../context/userContext";
import { getCatalog, getLivro, getPortfolio } from "../services/api";

export const useGetBooks = () => {
  const {
    setUserLibrary,
    setCatalog,
    setBookDetails,
    libLinhaInicial,
    libLinhaAdicional,
    libTypeSort,
    catLinhaInicial,
    catLinhaAdicional,
    catTypeSort,
  } = useUserContext();

  const getBooks = async (TOKEN, portfolio = true, catalog = false) => {
    (portfolio && setUserLibrary) && setUserLibrary(await getPortfolio(TOKEN, libLinhaInicial, libLinhaAdicional, libTypeSort));
    (catalog && setCatalog) && setCatalog(await getCatalog(TOKEN, catLinhaInicial, catLinhaAdicional, catTypeSort));
  };

  const getBookDetails = async (TOKEN, bookId) => {
    const payload = {
      token: TOKEN,
      bookId,
    };

    const response = await getLivro(payload);

    setBookDetails(response);
  }

  return {
    getBooks,
    getBookDetails,
  };
};
