import React from "react";
import { AspectRatio, Flex, Image } from "@chakra-ui/react";
import logo from "../../assets/images/logo.png";
import HelpModal from "./components/HelpModal";
import { LoginForm } from "./components/LoginForm";

const Login = () => {
  return (
    <Flex flexDir="column" w="80%" maxW="350px" align="center">
      <AspectRatio mb="10%" mt="10vh" w="70%" ratio={261 / 169}>
        <Image src={logo} alt="Logo" />
      </AspectRatio>
      <LoginForm />
      <HelpModal />
    </Flex>
  );
};

export { Login };
