import React, { useState, useEffect, createContext, useContext } from "react";
import { getCatalog, getPortfolio } from "../services/api";
import { getToken } from "../utils/helper";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [loadingHome, setLoadingHome] = useState(false);
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [showCatLen, setShowCatLen] = useState(false);
  const [userLibrary, setUserLibrary] = useState({});
  const [libLinhaInicial, setLibLinhaInicial] = useState(0);
  const [libLinhaAdicional, setLibLinhaAdicional] = useState(60);
  const [libTypeSort, setLibTypeSort] = useState(1);
  const [catalog, setCatalog] = useState({});
  const [catLinhaInicial, setCatLinhaInicial] = useState(0);
  const [catLinhaAdicional, setCatLinhaAdicional] = useState(20);
  const [catTypeSort, setCatTypeSort] = useState(1);
  const [catSearch, setCatSearch] = useState("");
  const [bookDetails, setBookDetails] = useState();
  const totalDeLivros = catalog?.totalDeLivros || 0;
  const UltimalinhaEnviada = catalog?.UltimalinhaEnviada;

  const getMoreCatalog = async (len) => {
    if (totalDeLivros > UltimalinhaEnviada) {
      setCatLinhaInicial(len + 1);
    }
  };

  const getCatalogBySearch = (term = "") => {
    setCatSearch(term);
  };

  useEffect(() => {
    const setLib = async () => {
      setLoadingHome(true);
      setUserLibrary(await getPortfolio(getToken(), libLinhaInicial, libLinhaAdicional, libTypeSort));
      setLoadingHome(false);
    };
    setLib();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libLinhaInicial, libLinhaAdicional, libTypeSort]);

  useEffect(() => {
    const setCat = async () => {
      const newBooks = await getCatalog(getToken(), catLinhaInicial, catLinhaAdicional, catTypeSort);
      setCatalog(prev => ({ ...prev, ...newBooks, listData: [...(prev?.listData || []), ...(newBooks.listData || [])] }));
    };
    setCat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catLinhaInicial, catLinhaAdicional]);

  useEffect(() => {
    const setCat = async () => {
      setLoadingCatalog(true);
      const newBooks = await getCatalog(getToken(), 0, catLinhaAdicional, catTypeSort);
      setCatalog(newBooks);
      setLoadingCatalog(false);
    };
    setCat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catTypeSort]);

  useEffect(() => {
    const setCat = async () => {
      const newBooks = await getCatalog(getToken(), 0, 20, catTypeSort, catSearch);
      setShowCatLen(catSearch === "" ? false : true);
      setCatalog(newBooks);
    };
    setCat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catSearch]);


  return (
    <UserContext.Provider
      value={{
        userLibrary,
        setUserLibrary,
        catalog,
        setCatalog,
        bookDetails,
        setBookDetails,
        libLinhaInicial,
        setLibLinhaInicial,
        libLinhaAdicional,
        setLibLinhaAdicional,
        libTypeSort,
        setLibTypeSort,
        catLinhaInicial,
        setCatLinhaInicial,
        catLinhaAdicional,
        setCatLinhaAdicional,
        catTypeSort,
        setCatTypeSort,
        getMoreCatalog,
        getCatalogBySearch,
        totalDeLivros,
        loadingCatalog,
        loadingHome,
        catSearch,
        showCatLen,
        setShowCatLen,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  return context;
};
