import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react'

import AudioPlayer from './components/AudioPlayer';

import './styles.css'

const AudioPlayerModal = ({
    isOpen,
    audioUrl,
    bookTitle,
    authorName,
    onHideModal,
    narratorName,
}) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onHideModal}>
            <ModalOverlay />
            <ModalContent w="400px" maxW="92%">
                <ModalHeader></ModalHeader>
                <ModalCloseButton _focus={{ boxShadow: "none" }} _active={{ boxShadow: "none" }} />

                <ModalBody>
                    <div className='modal-content-title text-main-color'>
                        {bookTitle}
                    </div>

                    <div className='modal-content-subtitle text-secondary-color'>
                        {authorName}
                    </div>


                    <AudioPlayer audioUrl={audioUrl} />

                    <div className='modal-content-subtitle'>
                        <p className='text-main-color'>
                            <span
                                className='text-main-color'
                            >
                                Narração: {narratorName}
                            </span>
                        </p>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default AudioPlayerModal
