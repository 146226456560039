import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Flex, Text, Link, Box } from "@chakra-ui/react";
import { content } from "./content";
import { usePathContext } from "../../context/pathContext";

const Help = () => {
  const { setActiveIndex } = usePathContext();

  return (
    <Flex flexDir="column">
      <Text
        color="gray"
        p="10px"
        fontWeight={500}
        fontSize="xl"
        align="center"
        textTransform="uppercase"
      >
        Como funciona
      </Text>
      {content.how.map((topic, index) => (
        <Text key={index} color="gray" p="10px">{topic}</Text>
      ))}
      <br />
      <br />
      <Text
        color="gray"
        p="10px"
        fontWeight={500}
        fontSize="xl"
        align="center"
        textTransform="uppercase"
      >
        Perguntas mais frequentes
      </Text>
      {content.faq.map((question, index) => (
        <Box key={index}>
          <Text color="gray" p="10px" fontWeight={700}>
            {question.title.trim()}
          </Text>
          <Text color="gray" p="10px">{question.answer.trim()}</Text>
        </Box>
      ))}
      <br />
      <br />
      <Text color="gray" p="10px" align="center">
        Não encontrou sua pergunta aqui? Então manda para a gente, que
        prometemos responder rapidinho!
      </Text>
      <Link as={RouterLink} onClick={() => setActiveIndex(1)} w="fit-content" m="0 auto" to="/fale-conosco">
        <Text
          color="gray"
          p="10px"
          textDecor="underline"
          fontSize="xl"
          fontWeight={500}
          textTransform="uppercase"
          w="fit-content"
        >
          Fale Conosco
        </Text>
      </Link>
    </Flex>
  );
};

export { Help };
