import { Box, Flex, Icon, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useUserContext } from "../../../../context/userContext";

export const Search = (props) => {
  const [term, setTerm] = useState("");
  const { getCatalogBySearch, catalog, showCatLen } = useUserContext();
  const catalogLen = catalog?.listData?.length;

  const handleSubmit = (e) => {
    e.preventDefault();
    getCatalogBySearch(term);
  };

  const handleTermChange = (e) => {
    const value = e.target.value;
    if (value === "") getCatalogBySearch();
    setTerm(value);
  };

  return (
    <Flex align="center" maxW="100%" justify="flex-end" {...props} mb={3}>
      {showCatLen && (
        <Text
          w="fit-content"
          textAlign="right"
          color="lightgray"
          fontSize={{ base: "xs", md: "sm" }}
          fontWeight={600}
          noOfLines={1}
          mr={2}
          maxW="calc(100vw - 200px)"
        >
          {catalogLen > 0 && catalogLen} {catalogLen === 0 ? "nada" : "livro"}{catalogLen > 1 && "s"} encontrado{catalogLen > 1 && "s"}
        </Text>
      )}
      <Box
        as="form"
        onSubmit={handleSubmit}
        pos="relative"
        zIndex={0}
        h="35px"
        w={{ base: "150px", sm: "170px", md: "190px" }}
      >
        <Input
          h="35px"
          w={{ base: "115px", sm: "135px", md: "155px" }}
          pos="absolute"
          right="35px"
          borderLeftRadius="md"
          borderRightRadius="none"
          borderRight="none"
          value={term}
          onChange={handleTermChange}
          borderColor="lightgray"
          borderWidth="2px"
          type="search"
          fontSize="sm"
          autoComplete="off"
          placeholder="Palavra-chave"
          _placeholder={{
              fontSize: "xs",
              color: "lightgray",
              opacity: 0.7,
            }}
        />
        <Flex
          as="button"
          type="submit"
          cursor="pointer"
          align="center"
          justify="center"
          w="35px"
          h="35px"
          borderRightRadius="md"
          border="2px solid #7d7d7d"
          pos="absolute"
          top={0}
          right={0}
        >
          <Icon color="othergray" as={AiOutlineSearch} />
        </Flex>
      </Box>
    </Flex>
  );
};
