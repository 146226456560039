import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useUserContext } from "../../context/userContext";
import { Library } from "../../components/Library";
import { Search } from "./components/Search";
import { InternalHeader } from "../../components/InternalHeader";

export const Catalog = (props) => {
  const { pageName } = props;
  const [initialNumber, setInitialNumber] = useState(
    window.innerWidth >= 440 ? 8 : 6
  );
  const { catalog, getCatalogBySearch, loadingCatalog } = useUserContext();
  const {
    listData,
    destaquecollectionId,
    destaquebookTitle,
    destaquebookAuthor,
    destaquebookCover,
    destaquebookCoverGIF,
    destaquebookAuthorGender,
    destaquebookIllustrator,
    destaquebookIllustratorGender,
    destaquebookbtOuvir,
    destaquebookbtLerOnLine,
    destaquebookJaEscolhido,
    destaqueMensagem,
  } = catalog;

  const emphasisData = {
    destaquecollectionId,
    destaquebookTitle,
    destaquebookAuthor,
    destaquebookCover,
    destaquebookCoverGIF,
    destaquebookAuthorGender,
    destaquebookIllustrator,
    destaquebookIllustratorGender,
    destaquebookbtOuvir,
    destaquebookbtLerOnLine,
    destaquebookJaEscolhido,
    destaqueMensagem,
  };

  window.addEventListener("resize", (ev) => {
    const width = ev?.target?.innerWidth;
    const newNumber = width >= 440 ? 8 : 6;

    newNumber !== initialNumber && setInitialNumber(newNumber);
  });

  useEffect(getCatalogBySearch, []);

  return (
    <Flex w="100%" flexDir="column" alignItems="center">
      {pageName && <InternalHeader pageName={pageName} />}
      <Search alignSelf="flex-end" />
      <Library
        hasEmphasis
        emphasisData={emphasisData}
        initialNumber={initialNumber}
        books={listData}
        loadingCatalog={loadingCatalog}
      />
    </Flex>
  );
};
