import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getLivro } from "../../services/api";
import { getToken, cancelFS } from "../../utils/helper";

const Epub = () => {
  const { bookId } = useParams();
  const { state } = useLocation();
  const [bookDownloadFile, setBookDownloadFile] = useState();
  const navigate = useNavigate();
  const iframeRef = useRef(null)

  const getBookData = useCallback(async () => {
    const response = await getLivro({ token: getToken(), bookId });
    setBookDownloadFile(response?.bookDownloadFile);
  }, [bookId])

  const handleClick = () => {
    cancelFS();
    setTimeout(() => navigate(`/detalhes/${bookId}`), 200);
  };

  useEffect(() => {
    getBookData();
  }, [getBookData]);

  useEffect(() => {
    if(!bookDownloadFile || !iframeRef.current) return

    iframeRef.current.focus()
  }, [bookDownloadFile])

  return (
    <Box>
      {bookDownloadFile && (
        <Box
          ref={iframeRef}
          pos="fixed"
          top={0}
          left={0}
          width='100vw'
          height='100vh'
          maxH='fill-available'
          as="iframe"
          className="not-iframe"
          sandbox="allow-same-origin allow-scripts allow-top-navigation"
          src={`${process.env.REACT_APP_READER_URL}/?book=${bookDownloadFile}&spy=${!!state?.spy}`}
          w="100vw"
          h="100vh"
        />
      )}
      <IconButton
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
        onClick={handleClick}
        cursor="pointer"
        top="32px"
        transform="translateY(-50%)"
        left={3}
        icon={<BiArrowBack size={30} />}
        bgColor="white"
        color="lightgray"
        pos="fixed"
        borderRadius="full"
      />
    </Box>
  );
};

export { Epub };
