import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

const HelpModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <>
      <Button
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
        h="fit-content"
        textDecor="underline"
        color="othergray"
        fontWeight={400}
        onClick={onOpen}
      >
        Primeiro acesso?
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="400px" maxW="92%">
          <ModalHeader fontFamily="primary" textAlign="center">Primeiro acesso</ModalHeader>
          <ModalCloseButton
            _focus={{ boxShadow: "none" }}
            _active={{ boxShadow: "none" }}
          />
          <ModalBody fontFamily="primary" textAlign="justify" fontSize={{base: "xs", xs: "sm", sm: "md"}}>
            <Text>Novo por aqui? Seja bem-vindo!</Text>
            <br />
            <Text>
              No Vamolê você escolhe a cada mês um novo livro para ler, baixar e
              chamar de seu! Os livros que você escolhe vão para sua biblioteca
              virtual aqui mesmo, e você pode ler quando quiser.
            </Text>
            <br />
            <Text>
              Vamos escolher seu primeiro livro? Não precisa fazer cadastro, é
              só informar seu número do celular da operadora parceira do Vamolê
              e os primeiros dígitos do seu CPF para entrar.
            </Text>
            <br />
            <Text>Então...vamolê!</Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Button
              _focus={{ boxShadow: "none" }}
              _active={{ boxShadow: "none" }}
              color="othergray"
              bgColor="ligreen"
              mr={3}
              onClick={onClose}
              fontFamily="primary"
            >
              Entendi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HelpModal;
