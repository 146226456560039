import React from "react";
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import like from "../../../../assets/images/icons/vamole-icon-like.png";
import stop from "../../../../assets/images/icons/vamole-icon-stop.png";

export const ChooseBookIndicator = (props) => {
  const { currentMonthName, livroDoMes } = props;
  const content = {
    false: {
      firstLine: "Você ainda não escolheu",
      secondLine: `seu livro de ${currentMonthName}`,
      callToAction: "escolher agora",
      icon: (
        <Image
          src={stop}
          w={{base: "45px", xs: "50px", sm: "55px", md: "60px", lg: "65px"}}
          filter="brightness(0) saturate(100%) invert(68%) sepia(42%) saturate(430%) hue-rotate(125deg) brightness(82%) contrast(99%)"
        />
      ),
      colorOne: "othergray",
      colorTwo: "othergray",
      bgColor: "#F8E22E",
    },
    true: {
      firstLine: "Já adicionamos o livro de",
      secondLine: `${currentMonthName} à sua biblioteca`,
      callToAction: "navegar pelo catálogo",
      icon: (
        <Image
          src={like}
          w={{base: "45px", xs: "50px", sm: "55px", md: "60px", lg: "65px"}}
          filter="brightness(0) saturate(100%) invert(87%) sepia(13%) saturate(1604%) hue-rotate(37deg) brightness(108%) contrast(107%)"
        />
      ),
      colorOne: "white",
      colorTwo: "#C0FF6B",
      bgColor: "gblue",
    },
  };
  const {
    firstLine,
    secondLine,
    callToAction,
    icon,
    colorOne,
    colorTwo,
    bgColor,
  } = content[!!livroDoMes] || {};

  return (
    currentMonthName && (
      <Box
        w="100%"
        maxW={{ base: "280px", sm: "320px", md: "360px", lg: "400px" }}
        p={{ base: "15px 15px 15px 25px", sm: "15px 15px 15px 30px" }}
        borderRadius="xl"
        boxShadow="3px 3px 8px #00000033"
        bgColor={bgColor}
      >
        <Flex
          gap={2}
          justify="center"
          align="center"
        >
          <Box textAlign="center" mr={2}>
            <Box alignItems="center">
              <Text
                fontWeight={700}
                color={colorOne}
                fontSize={{ base: "xs", xs: "sm", sm: "md", md: "lg" }}
                whiteSpace="nowrap"
              >
                {firstLine}
              </Text>
              <Text
                fontWeight={700}
                color={colorOne}
                fontSize={{ base: "xs", xs: "sm", sm: "md", md: "lg" }}
                whiteSpace="nowrap"
              >
                {secondLine}
              </Text>
            </Box>
            <Link
              as={RouterLink}
              to="/catalogo"
            >
              <Button
                _focus={{ boxShadow: "none" }}
                _active={{ boxShadow: "none" }}
                fontWeight={400}
                color={colorTwo}
                textDecor="underline"
                h="fit-content"
                p={0}
                fontSize={{ base: "xs", sm: "sm", md: "md" }}
              >
                {callToAction}
              </Button>
            </Link>
          </Box>
          {icon}
        </Flex>
      </Box>
    )
  );
};
