import React from "react";
import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { LogoSide } from "../LogoSide";
import { FooterMenu } from "../FooterMenu";
import PushNotificationModal from "../pushNotificationModal";
import PwaScreen from "../pwa-screen";

export const Main = (props) => {
  const { hasLogo, hasFooter, showAlerts, bgColor } = props;

  return (
    <>
      {showAlerts && (
        <>
          <PushNotificationModal showEnable />
          <PwaScreen />
        </>
      )}
      <Flex
        fontFamily="primary !important"
        pt={hasLogo ? "calc(min(50vw, 300px) / 34 * 19)" : "70px"}
        pb={hasFooter && "90px"}
        px="20px"
        pos="relative"
        flexDir="column"
        maxW="600px"
        m="0 auto"
        align="center"
        bgColor={bgColor}
        bgImg={!bgColor && "linear-gradient(155deg, #ddd, #fff, #ddd)"}
        shadow="0 0 15px #00000055"
        h="100vh"
        overflowY="auto"
      >
        {hasLogo && <LogoSide />}
        <Outlet />
        {hasFooter && <FooterMenu />}
      </Flex>
    </>
  );
};
