import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { usePushContext } from '../../context/pushContext';
import bg from '../../assets/images/pwa-mdc-modal-alert-bg.png';

function PushNotificationModal({ showEnable }) {
	const {
		showPushModal,
		acceptPushNotification,
		rejectPushNotification,
	} = usePushContext();

	if (!showPushModal || !showEnable) return null;

	return (
		<Flex
			pos="fixed"
			zIndex={2}
			justify="center"
			align="center"
			w="100%"
			maxW="600px"
			left="50%"
			transform="translateX(-50%)"
			top={0}
			h="100vh"
			bgColor="#00000088"
		>
			<Box
				pos="relative"
				bgImg={bg}
				bgColor="gblue"
				boxShadow="xl"
				bgSize="contain"
				borderRadius="30px"
				w="80vw"
				maxW="480px"
				h="80vw"
				maxH="480px"
			>
				<Flex
					justify="center"
					align="center"
					pos="absolute"
					color="othergray"
					w="3rem"
					h="3rem"
					fontSize="3rem"
					lineHeight="3rem"
					right="0.5rem"
					top="0.5rem"
					p="0px"
					m="0px"
					cursor="pointer"
					userSelect="none"
					onClick={rejectPushNotification}
				>
					&times;
				</Flex>
				<Flex
					direction="column"
					position="absolute"
					w="45%"
					bottom="10%"
					left="10%"
				>
					<Button
						_focus={{ boxShadow: "none" }}
						_active={{ boxShadow: "none" }}
						mb="5%"
						size="sm"
						borderRadius="xl"
						color="othergray"
						bgColor="buttongreen"
						onClick={acceptPushNotification}
					>
						Aceito
					</Button>
					<Button
					 	_focus={{ boxShadow: "none" }}
						 _active={{ boxShadow: "none" }}
						size="sm"
						borderRadius="xl"
						color="othergray"
						bgColor="white"
						onClick={rejectPushNotification}
					>
						Agora não
					</Button>
				</Flex>
			</Box>
		</Flex>
	);
}

export default PushNotificationModal;