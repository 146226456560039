import { AspectRatio, Box, Flex, GridItem, Icon, Text } from "@chakra-ui/react";
import { AiFillStar } from "react-icons/ai";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Book = (props) => {
  const { empty, cover, title, author, favorited, bookId } = props;
  const navigate = useNavigate();

  const handleSeeDetails = () => {
    navigate(`/detalhes/${bookId}`);
  };

  return empty ? (
    <GridItem
      as={AspectRatio}
      w="100%"
      ratio={1}
      // border="1px solid #ccc"
      borderRadius="lg"
    >
      <Box />
    </GridItem>
  ) : (
    <GridItem
      as={Flex}
      w="100%"
      flexDir="column"
      align="center"
      textAlign="center"
      overflow="visible !important"
    >
      <Flex
        as={AspectRatio}
        w="100%"
        ratio={1}
        flexDir="column"
        align="center"
        justify="flex-end"
        textAlign="center"
        overflow="visible !important"
        mb="0.3rem"
      >
        <Flex h="100%" overflow="visible !important">
          <Box h="100%" pos="relative" overflow="visible !important">
            <Box
              onClick={handleSeeDetails}
              cursor="pointer"
              as="img"
              h="100%"
              src={cover}
              objectFit="contain"
              borderRadius="md"
              boxShadow="2px 2px 6px #00000066"
            />
            {!!favorited && (
              <Icon
                w={35}
                h={35}
                color="#F8E22E"
                filter="drop-shadow(0 0 4px #00000088)"
                pos="absolute"
                top="-12px"
                right="-12px"
                as={AiFillStar}
              />
            )}
          </Box>
        </Flex>
      </Flex>
      <Box w="90%" h="2.5rem">
        <Text
          w="100%"
          color="lightgray"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          fontSize="sm"
          fontWeight={700}
        >
          {title}
        </Text>
        <Text
          w="100%"
          color="lightgray"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          fontSize="xs"
          fontWeight={400}
        >
          {author}
          {/* {author.replace("De ", "")} */}
        </Text>
      </Box>
    </GridItem>
  );
};
