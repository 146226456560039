import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  Flex,
  FormLabel,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { loginWithCPF, loginWithPhone } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { usePathContext } from "../../../../context/pathContext";
import { useUserContext } from "../../../../context/userContext";

const LoginForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const { setActiveIndex } = usePathContext();
  const { setUserLibrary } = useUserContext();
  const [phoneLogin, setPhoneLogin] = useState(true);

  const onSubmit = async (values) => {
    const response = phoneLogin
      ? await loginWithPhone(values)
      : await loginWithCPF(values);

    if (response === false)
      return toast({
        title: "Login inválido",
        description: "Campos inválidos! Tente novamente!",
        status: "error",
        duration: 6000,
        isClosable: true,
      });

    const { token } = response;

    localStorage.setItem("@vml/hash", token);
    setUserLibrary(response);
    navigate("/");
    setActiveIndex(2);
  };

  return (
    <Flex
      w="100%"
      as="form"
      flexDir="column"
      align="center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl
        mb="30px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="othergray"
      >
        <FormLabel
          m="0"
          textAlign="right"
          fontSize="xs"
          opacity={!phoneLogin && 0.5}
        >
          <Text>Entrar com</Text>
          <Text>celular</Text>
        </FormLabel>
        <Switch
          m="0 0.8rem"
          bgColor="#5FA677"
          borderRadius="full"
          colorScheme="#5FA677"
          onChange={() => setPhoneLogin((prev) => !prev)}
        />
        <FormLabel
          m="0"
          textAlign="left"
          fontSize="xs"
          opacity={phoneLogin && 0.5}
        >
          <Text>Entrar com</Text>
          <Text>CPF/CNPJ</Text>
        </FormLabel>
      </FormControl>

      {phoneLogin ? (
        <>
          <FormControl marginBottom="15px" isInvalid={errors.phone}>
            <Input
              as={InputMask}
              mask="(99) 99999-9999"
              maskChar={null}
              autoComplete="off"
              _placeholder={{
                textAlign: "center",
                color: "#999",
                fontSize: "xs",
                fontWeight: 400,
              }}
              borderRadius="xl"
              bgColor="white"
              border="none"
              id="phone"
              type="tel"
              placeholder="Digite seu número de telefone com DDD"
              {...register("phone", {
                required: "Campo obrigatório",
              })}
            />
            <FormErrorMessage>
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <Input
              as={InputMask}
              mask="999"
              maskChar={null}
              autoComplete="off"
              _placeholder={{
                textAlign: "center",
                color: "#999",
                fontSize: "xs",
                fontWeight: 400,
              }}
              borderRadius="xl"
              bgColor="white"
              border="none"
              id="password"
              placeholder="Digite os três primeiros dígitos do seu CPF"
              {...register("password", {
                required: "Campo obrigatório",
              })}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
        </>
      ) : (
        <FormControl isInvalid={errors.cpf}>
          <Input
            _placeholder={{
              textAlign: "center",
              color: "#999",
              fontSize: "xs",
              fontWeight: 400,
            }}
            autoComplete="off"
            borderRadius="xl"
            bgColor="white"
            border="none"
            id="cpf"
            placeholder="Digite seu CPF / CNPJ completo"
            {...register("cpf", {
              required: "Campo obrigatório",
              minLength: { value: 11, message: "Digite um CPF ou CNPJ válido" },
            })}
          />
          <FormErrorMessage>
            {errors.cpf && errors.cpf.message}
          </FormErrorMessage>
        </FormControl>
      )}

      <Button
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
        borderRadius="xl"
        w="50%"
        my="40px"
        bgColor="ligreen"
        color="othergray"
        isLoading={isSubmitting}
        type="submit"
      >
        entrar
      </Button>
    </Flex>
  );
};

export { LoginForm };
