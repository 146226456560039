import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { TalkToUsForm } from "./components/TalkToUsForm";

const TalkToUs = () => {
  return (
    <Flex
      alignItems="center"
      flexDir="column"
      w="90%"
      maxW="400px"
      textAlign="center"
    >
      <Text p="10px" fontSize="2xl" fontWeight={500} color="gray">FALE CONOSCO</Text>
      <Text p="30px 10px 10px" color="gray">
        Ficou com alguma dúvida ou quer dar sua opinião? Mande para o Vamolê!
      </Text>
      <TalkToUsForm />
    </Flex>
  );
};

export { TalkToUs };
