import React from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  Flex,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { sendMessage } from "../../../../services/api";

const TalkToUsForm = () => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();
  const toast = useToast();

  const onSubmit = async (values) => {
    const response = await sendMessage(values);

    if (response === false)
      return toast({
        title: "Erro",
        description: "Não foi possível enviar sua mensagem.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });

    toast({
      title: "Mensagem enviada",
      description: "Agradecemos o contato. Em breve responderemos.",
      status: "success",
      duration: 6000,
      isClosable: true,
    });

    reset();
  };

  return (
    <Flex
      w="100%"
      as="form"
      flexDir="column"
      align="center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl marginBottom="15px" isInvalid={errors.name}>
        <Input
          _placeholder={{
            color: "#999",
            fontSize: "xs",
            fontWeight: 400,
          }}
          autoComplete="off"
          borderRadius="xl"
          bgColor="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="othergray"
          id="name"
          placeholder="Nome"
          {...register("name", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl marginBottom="15px" isInvalid={errors.email}>
        <Input
          type="email"
          _placeholder={{
            color: "#999",
            fontSize: "xs",
            fontWeight: 400,
          }}
          autoComplete="off"
          borderRadius="xl"
          bgColor="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="othergray"
          id="email"
          placeholder="E-mail"
          {...register("email", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl marginBottom="15px" isInvalid={errors.phone}>
        <Controller
          name="phone"
          rules={{
            minLength: {
              value: 15,
              message: "Número inválido",
            },
            required: {
              value: true,
              message: "Campo obrigatório",
            },
          }}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="(99) 99999-9999"
              maskChar={null}
              value={value}
              onChange={onChange}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  _placeholder={{
                    color: "#999",
                    fontSize: "xs",
                    fontWeight: 400,
                  }}
                  autoComplete="off"
                  borderRadius="xl"
                  bgColor="white"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="othergray"
                  id="phone"
                  placeholder="Número do celular com DDD"
                />
              )}
            </InputMask>
          )}
        />
        <FormErrorMessage>
          {errors.phone && errors.phone.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl marginBottom="15px" isInvalid={errors.message}>
        <Textarea
          _placeholder={{
            color: "#999",
            fontSize: "xs",
            fontWeight: 400,
          }}
          borderRadius="xl"
          bgColor="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="othergray"
          id="message"
          placeholder="Mensagem"
          {...register("message", {
            required: "Campo obrigatório",
          })}
        />
        <FormErrorMessage>
          {errors.message && errors.message.message}
        </FormErrorMessage>
      </FormControl>

      <Button
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
        borderRadius="xl"
        w="50%"
        my="10px"
        bgColor="transparent"
        color="othergray"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="othergray"
        isLoading={isSubmitting}
        type="submit"
      >
        Enviar
      </Button>
    </Flex>
  );
};

export { TalkToUsForm };
