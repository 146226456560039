import React from 'react'

import secondsToTime from '../../../../../../utils/secondsToTime';

import useProgressBar from './hooks/useProgressBar';

import './styles.css'

const ProgressBar = ({ totalDuration, currentDuration, onChangeDuration }) => {
    const { handleOnChangeDuration } = useProgressBar({ onChangeDuration })

    const currentListenedPercentage = (currentDuration * 100) / totalDuration

    const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentListenedPercentage}%, #484848), color-stop(${currentListenedPercentage}%, #aed145))
    `;

    return (
        <div className='progress-bar-wrapper'>
            <input
                min='0'
                step='1'
                type='range'
                max={totalDuration}
                value={currentDuration}
                onChange={handleOnChangeDuration}
                style={{ background: trackStyling }}
            />

            <div className='durations current-duration'>{secondsToTime(currentDuration)}</div>

            <div className='durations total-duration'>{secondsToTime(totalDuration)}</div>
        </div>
    )
}

export default ProgressBar