import React from "react";
import { Flex, Text, HStack, StackDivider, Icon, Link } from "@chakra-ui/react";
import { MdOutlineHelp } from "react-icons/md";
import { GiTalk } from "react-icons/gi";
import { AiFillHome } from "react-icons/ai";
import { FaFileContract } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { usePathContext } from "../../context/pathContext";

const FooterMenu = () => {
  const navigate = useNavigate();
  const { activeIndex, setActiveIndex } = usePathContext();

  const footerButtons = [
    {
      id: 3,
      text: "Início",
      icon: AiFillHome,
      to: "/",
      onClick: () => {
        setActiveIndex(2);
      },
    },
    {
      id: 1,
      text: "Ajuda",
      icon: MdOutlineHelp,
      to: "/ajuda",
      onClick: () => {
        setActiveIndex(0);
      },
    },
    {
      id: 4,
      text: "Termos",
      icon: FaFileContract,
      to: "/termos",
      onClick: () => {
        setActiveIndex(3);
      },
    },
    {
      id: 2,
      text: "Contato",
      icon: GiTalk,
      to: "/fale-conosco",
      onClick: () => {
        setActiveIndex(1);
      },
    },
    {
      id: 5,
      text: "Sair",
      icon: BiLogOut,
      to: "/login",
      onClick: () => {
        logout();
        setActiveIndex(2);
      },
    },
  ];

  const logout = () => {
    window.localStorage.removeItem("@vml/hash");
    navigate("/login");
    window.location.reload();
  };

  return (
    <HStack
      borderTopLeftRadius="30px 20px"
      borderTopRightRadius="30px 20px"
      divider={<StackDivider borderColor="gray" />}
      bgColor="secondary"
      shadow="0 0 5px #00000055"
      h="70px"
      w="100%"
      maxW="600px"
      p="10px"
      pos="fixed"
      zIndex={1}
      left="calc(50% - min(50vw, 300px))"
      bottom={0}
      userSelect="none"
    >
      {footerButtons.map(button => (
        <Link
          key={button.id}
          draggable="false"
          as={RouterLink}
          onClick={button.onClick}
          to={button.to}
          flex="1"
          align="center"
          h="100%"
          color={button.id - 1 === activeIndex ? "white" : "#283920"}
          opacity={button.id - 1 === activeIndex ? 1 : .7}
          _hover={{ textDecor: "none" }}
          _focus={{ boxShadow: "none" }}
          _active={{ color: "white", boxShadow: "none" }}
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            h="100%"
          >
            <Icon as={button.icon} m="0 0 5px" />
            <Text lineHeight="1" fontSize="xs" fontWeight={700} align="center">
              {button.text}
            </Text>
          </Flex>
        </Link>
      ))}
    </HStack>
  );
};

export { FooterMenu };
